import React from 'react';
import { useStyles } from './styles';
import { textConfig } from '../../global/text';
import { upgradePlan } from '../../global/utils/plan';

const QuotaReached = ({ plan }) => {
  const classes = useStyles();

  return (
    <div className={classes.quotaReachedLabel}>
      <span>{textConfig.specificPage.quotaReached.part1}</span>
      {plan.available.length > 0 && (
        <span>
          <span
            className={classes.upgradeLabel}
            onClick={() => {
              upgradePlan(plan.available);
            }}
          >
            {textConfig.specificPage.quotaReached.upgrade}
          </span>
          <span>{textConfig.specificPage.quotaReached.part2}</span>
        </span>
      )}
    </div>
  );
};

export default QuotaReached;
