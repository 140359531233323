import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  quotaReachedLabel: {
    width: '280px',
    marginLeft: '15px',
  },
  upgradeLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
}));

export { useStyles };
