import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useDispatch, useSelector } from 'react-redux';
import { SpecificPage } from '../SpecificPage';
import { BlogSubheader } from '../../components/Subheader';
import { MainSubheader } from '../../components/Subheader';
import { CollapsibleList } from '../../components/CollapsibleList';
import { CustomSlide } from '../../components/CustomSlide';
import { GenericError } from '../../components/ErrorPages';
import { PublishSnackbar } from '../../components/PublishSnackbar';
import { upgradePlan } from '../../global/utils/plan';
import { useStyles } from './styles';
import { textConfig } from '../../global/text';
import Loader from '../../global/static/loader.svg';
import { storeDashScroll } from '../../actions';
import QuotaReached from '../../components/QuotaReached/QuotaReached';
import { QuotaReachedOneLine } from '../../components/QuotaReached';

const Dashboard = () => {
  const pages = useSelector(state => state.pages.pagesList);
  const error = useSelector(state => state.pages.error);
  const freeTrial = useSelector(state => state.main.freeTrial);
  const plan = useSelector(state => state.main.plan);
  const initialLoading = useSelector(state => state.pages.initialLoading);
  const dashScroll = useSelector(state => state.main.dashboardScroll);
  const [details, setDetails] = useState({ data: {}, status: false, type: null });
  const quotaReached = pages?.metadata?.quota_reached;
  const dispatch = useDispatch();
  useEffect(() => {
    if (dashScroll !== 0) {
      const element = document.getElementById(dashScroll);
      if (element) {
        element.scrollIntoView({ block: 'center' });
        dispatch(storeDashScroll(0));
      }
    }
  });

  const classes = useStyles();

  useEffect(() => {
    if (details.status) {
      const page = pages[details.type].find(item => item.page_id === details.data.page_id);
      setDetails(prevState => ({ ...prevState, data: page }));
    }
  }, [pages]);

  const handleBack = () => {
    setDetails({ data: {}, status: false, type: null });
  };

  const handleShow = (data, id, type) => {
    dispatch(storeDashScroll(id));
    setDetails({ data, status: true, type });
  };

  const handleShowCallback = useCallback(() => handleBack(), []);

  if (initialLoading) {
    return <Loader className={classes.spinner} />;
  }

  return (
    <div className={classes.root}>
      <PublishSnackbar />
      <CustomSlide open={details.status}>
        <SpecificPage
          data={details.data}
          open={details.status}
          quotaReached={quotaReached}
          handleBack={handleShowCallback}
        />
      </CustomSlide>
      {!details.status && (
        <div className={classes.container}>
          {error !== null && isEmpty(pages) ? (
            <GenericError text={textConfig.errors.pagesError} />
          ) : (
            <>
              <div className={classes.header}>
                <h3>{textConfig.dashboardPage.title}</h3>
                {plan?.name && freeTrial?.days_left && freeTrial?.days_left ? (
                  <span>
                    {textConfig.dashboardPage.tipParts.plan1}
                    <span
                      className={classes.upgradeLabel}
                      onClick={() => {
                        upgradePlan(plan.available);
                      }}
                    >
                      {freeTrial.days_left < 0 ? '-' : freeTrial.days_left}
                    </span>{' '}
                    {textConfig.dashboardPage.tipParts.plan2}
                    <span
                      className={classes.upgradeLabel}
                      onClick={() => {
                        upgradePlan(plan.available);
                      }}
                    >
                      {textConfig.global.upgrade}
                    </span>
                    {textConfig.dashboardPage.tipParts.plan3}
                  </span>
                ) : (
                  <>
                    <span>Ampify any page to optimize speed, search rank and user experience.</span>
                    {quotaReached && <QuotaReachedOneLine plan={plan} />}
                  </>
                )}
              </div>
              <div>
                {pages.main && (
                  <div>
                    {' '}
                    <CollapsibleList
                      data={pages.main}
                      header={{ name: textConfig.dashboardPage.mainPages }}
                      isOpen={!!pages.main.length}
                      subheader={MainSubheader}
                      handleShow={(data, id) => handleShow(data, id, 'main')}
                    />
                  </div>
                )}
                {pages.blog && (
                  <div className={classes.advancedPages}>
                    <CollapsibleList
                      data={pages.blog}
                      header={{ name: textConfig.dashboardPage.blogPosts }}
                      isOpen={!!pages.blog.length}
                      subheader={BlogSubheader}
                      handleShow={(data, id) => handleShow(data, id, 'blog')}
                    />
                  </div>
                )}
                {pages.product && (
                  <div className={classes.advancedPages}>
                    <CollapsibleList
                      data={pages.product}
                      header={{
                        name: textConfig.dashboardPage.productPages,
                        tooltip: textConfig.dashboardPage.productPagesTooltip,
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
