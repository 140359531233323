import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: '10px',
    height: 'calc(100% - 120px)',
  },
  section: {
    margin: '5px 0',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > span': {
      marginBottom: '20px',
    },
  },
  sectionContent: {
    '& > div': {
      marginBottom: '15px',
    },
  },
  sectionContentBlack: {
    '& > div': {
      marginBottom: 0,
    },
  },
  helpIcon: {
    color: theme.palette.grey.light,
    cursor: 'pointer',
    'align-self': 'baseline',
  },
  clickable: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
}));

export { useStyles };
