import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Help } from '@material-ui/icons';
import { ElementCard } from '../../components/ElementCard';
import { BlackElement } from '../../components/BlackElement';
import { Lightbox } from '../../components/Lightbox';
import { useStyles } from './styles';
import { textConfig } from '../../global/text';
import { Link as RouterLink } from 'react-router-dom';

const PageValidationSection = ({ rules, handleApproveClick, handleDeleteClick }) => {
  const classes = useStyles();
  const [lightbox, setLightboxState] = useState({ state: false, value: '', children: null });
  const hasGrey = rules.grey.length;
  const [hasPendingGrey] = useState(
    hasGrey &&
      rules.grey.some(rule => {
        return rule.action === 'awaiting';
      }),
  );
  const renderGreyRulesLightboxList = classes => (
    <ul>
      <li>{textConfig.specificPage.rules.grey.list.item1}</li>
      <li>{textConfig.specificPage.rules.grey.list.item2}</li>
      <li>{textConfig.specificPage.rules.grey.list.item3}</li>
      <li>
        {textConfig.specificPage.rules.grey.list.item4}{' '}
        <RouterLink
          className={classes.clickable}
          to={{ pathname: 'guides', state: { subpath: 'customized_sites#custom-built-widgets' } }}
        >
          {textConfig.global.clickHere}
        </RouterLink>
      </li>
    </ul>
  );

  return (
    <div className={classes.root}>
      {lightbox.state && (
        <Lightbox
          open={lightbox.state}
          text={lightbox.value}
          handleClose={() => setLightboxState({ state: false, value: '', children: null })}
        >
          {lightbox.children}
        </Lightbox>
      )}
      {hasGrey ? (
        <div className={classes.section}>
          {hasPendingGrey ? (
            <div className={classes.sectionTitle}>
              <span>{textConfig.specificPage.rules.grey.title}</span>
              <Help
                className={classes.helpIcon}
                fontSize="small"
                onClick={() =>
                  setLightboxState({
                    state: true,
                    children: renderGreyRulesLightboxList(classes),
                  })
                }
              />
            </div>
          ) : null}
          <div className={classes.sectionContent}>
            {rules.grey.map((item, index) => (
              <ElementCard
                key={index}
                item={item}
                onApprove={useCallback(() => handleApproveClick(item.idx), [])}
                onDelete={useCallback(() => handleDeleteClick(item.idx), [])}
              />
            ))}
          </div>
        </div>
      ) : null}
      {rules.black.length ? (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            <span>{textConfig.specificPage.rules.black.title}</span>
            <Help
              className={classes.helpIcon}
              fontSize="small"
              onClick={() =>
                setLightboxState({
                  state: true,
                  value: textConfig.specificPage.rules.black.lightbox,
                })
              }
            />
          </div>
          <div className={classes.sectionContentBlack}>
            {rules.black.map(item => (
              <BlackElement key={item.id} name={item.name} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

PageValidationSection.propTypes = {
  rules: PropTypes.object,
  handleApproveClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
};

export default PageValidationSection;
